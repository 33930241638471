import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { TelaService } from '@services/tela.service';
import { ToastrService } from 'ngx-toastr';
import { CriptografiaService } from '@services/criptografia.service';
const BASE_CLASSES = 'main-sidebar elevation-4';
import * as CryptoJS from 'crypto-js';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu: any = [];
    public perfil;
    public idUsuario;
    public token;

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private api: TelaService,
        private toastr: ToastrService,
        private apiCripto: CriptografiaService
    ) { }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.appService.getPerfil();
        this.appService.getIdUsuario();
        this.appService.getToken();
        this.idUsuario = this.appService.idUsuario;
        this.token = this.appService.token;
        this.perfil = this.appService.perfil;


        this.generateMenu();
    }

    generateMenu() {

        const payload = {
            idUsuario: this.idUsuario,
            token: this.token
        }
        this.api.getPerfilTela(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(res => {
                if (res.status === 'Sucesso') {
                    var temp = res.data;
                  
                    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
                        .subscribe(e => {
                            var tempKey = e.data;
                            var keyPadrao = this.apiCripto.getKeyPadrao();
                            var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

                            temp.forEach(e => {
                                var mostra_menu = this.apiCripto.decryptUsingAES256(e.mostra_menu, keyUser);
                                
                                if (mostra_menu == 1) {
                                    const temp1 = {
                                        name: this.apiCripto.decryptUsingAES256(e.nm_tela, keyUser),
                                        iconClasses: this.apiCripto.decryptUsingAES256(e.icone, keyUser),
                                        path: [this.apiCripto.decryptUsingAES256(e.url, keyUser)],
                                        show: false
                                    };
                                    this.menu.push(temp1);
                                }
                            });
                        });
                }
                else {
                    this.toastr.error(res.msg);
                }
            });
        /*
        if (this.perfil === 'Administrador') {
            const temp1 = {
                name: 'Usuário',
                iconClasses: 'fas fa-user',
                path: ['/lst-usuario'],
                show: false
            };

            this.menu.push(temp1);

            const temp2 = {
                name: 'Perfil',
                iconClasses: 'fas fa-user-circle',
                path: ['/lst-perfil'],
                perfil: 'Administrador',
                show: false
            }

            this.menu.push(temp2);
        }

        const temp = {
            name: 'Consulta NF',
            iconClasses: 'fab fa-sistrix',
            path: ['/consulta-nf'],
            show: true
        };
        this.menu.push(temp);*/

    }
}
/*
export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/blank']
    },
    {
        name: 'Main Menu',
        iconClasses: 'fas fa-folder',        
        children: [
            {
                name: 'Sub Menu',
                iconClasses: 'far fa-address-book',
                path: ['/sub-menu-1']
            },
            {
                name: 'Blank',
                iconClasses: 'fas fa-file',
                path: ['/sub-menu-2']
            }
        ]
    }
];
*/
/*
export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/'],
        show:false
    },
    {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/blank'],
        show:false
    },
  
    {
        name: 'Usuário',
        iconClasses: 'fas fa-user',
        path: ['/lst-usuario'],        
        show:false
    },
    {
        name: 'Perfil',
        iconClasses: 'fas fa-user-circle',
        path: ['/lst-perfil'],
        perfil: 'Administrador',
        show:false
    },
    {
        name: 'Consulta NF',
        iconClasses: 'fab fa-sistrix',
        path: ['/consulta-nf'],
        show:false
    },
    
];
*/
